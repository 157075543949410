"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stateReducer = void 0;

var stateReducer = function stateReducer(initialState, states) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var stateChange = states[action.type];

    if (stateChange) {
      return stateChange(state, action.payload);
    }

    return state;
  };
};

exports.stateReducer = stateReducer;